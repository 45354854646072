import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import InfoCard from "./components/InfoCard";
import CardRow from "./components/CardRow";

export default function View() {
    return <Box style={{ backgroundColor: '#101010', width: '90vx', borderRadius: '20px', padding: '15px' }}>
        <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
                <Typography variant="h4" style={{ color: 'text.primary' }}>ZombieB</Typography>
                <Typography variant="body1">Information Technology - Computer Programming</Typography>
            </div>
            <a href="https://x.com/nitijou_rkgk/status/1826895189664989205" target="_blank"><img src='/miku.jpg' width={74} height={74} style={{ borderRadius: '50%', border: '3px solid white' }}></img></a>
        </div>
        <Divider sx={{ mt: '8px' }}/>
        <CardRow>
            <InfoCard>
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>proxnet.dev Operator</Typography>
                <Typography variant="body2">I own this domain, and I use it for nothing but my personal projects.</Typography>
            </InfoCard>
            <InfoCard>
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>Student</Typography>
                <Typography variant="body2">At the latter end of high school, + the latter end of a vocational school.<br/>Self-taught IT and programming skills.</Typography>
            </InfoCard>
            <InfoCard>
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>Web Developer</Typography>
                <Typography variant="body2">Experienced in developing for the web.<br/>TypeScript, JavaScript, React + Parcel + MUI, Express</Typography>
            </InfoCard>
        </CardRow>
        <CardRow>
            <InfoCard>
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>proxnet.dev Game Services</Typography>
                <Typography variant="body2">I privately operate game services for those who ask me - this includes server space for Minecraft, Source games, and others.</Typography>
            </InfoCard>
            <InfoCard>
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>Programming</Typography>
                <Typography variant="body2">Outside of web development, I'm familiar with C# .NET, Java, and XML (including its derivatives).</Typography>
            </InfoCard>
            <InfoCard>
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>Pretty Average Uptime</Typography>
                <Typography variant="body2">proxnet (intentionally without capitalization) has a <span style={{ textDecoration: 'italic' }}>✨ fairly decent ✨</span> uptime. Definitely not datacenter levels of uptime, but it serves well.</Typography>
            </InfoCard>
        </CardRow>
    </Box>
}