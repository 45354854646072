import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InlineCode from "./components/InlineCode";

type CounterResponse = {
    c: number
}

export default function Counter() {

    const [count, setCount] = useState('(unknown)');

    useEffect(() => {
        async function getCount() {
            const res = await fetch('/counter/increment');
            if (res.status > 400) {
                setCount('(request failed)');
            }
            const body: CounterResponse = await res.json();
            setCount(`${body.c}`);
        }
        getCount();
    }, []);

    return <Box style={{ backgroundColor: '#101010', width: '90vx', borderRadius: '20px', padding: '15px', marginTop: '15px' }}>
        <Typography variant="h5" style={{ textAlign: 'center' }}>Visit Count: <InlineCode>{count}</InlineCode></Typography>
    </Box>
}