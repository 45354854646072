import { Card, CardContent } from "@mui/material";
import React from "react";

export default function InfoCard({ children }) {
    return <Card sx={{ ml: '8px', mr: '8px', borderRadius: '10px' }}>
        <React.Fragment>
            <CardContent>
                {children}
            </CardContent>
        </React.Fragment>
    </Card>
}