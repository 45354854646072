import React from "react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import View from "./View";
import Counter from "./Counter";

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});

export default function App() {
    return (<ThemeProvider theme={theme}>
        <CssBaseline />
        <main style={{ margin: '5% 20% 5% 20%', userSelect: 'none' }}>
            {View()}
            {Counter()}
        </main>
    </ThemeProvider>);
}